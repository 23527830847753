import Layout from "components/layout";
import SEO from "components/seo";
import ToolsBreadcrumb from "components/ToolsBredcrumb";
import { Link, navigate } from "gatsby";
import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "@reach/router";
import { endpoints } from "utils/endpoints";
import http from "utils/http";
import Loader from "components/loader";
import SourceOutput from "components/QualityTools/SourceOutput";
import ToolFaq from "components/QualityTools/ToolFaq";
import { SourceTextCheckerToolFaqData } from "components/FAQ/config";
import UploadIcon from "assets/uploadIcon.svg";
import FileLoadingInput from "components/FileUploading/fileloadinginput";
import ReUploadFile from "components/FileUploading/reUploadingfile";
import UnsupportedFile from "components/FileUploading/unsupportedfile";
import { fileExtension } from "utils/fileextension";
import { Languages } from "components/QualityTools/LanguagesData";
import axios from "axios";
import useDebounce from "components/QualityTools/useDebounce";
import detectLanguage from "utils/detectLanguage";
import { direction } from "utils/direction";
import { trySampleText } from "utils/sampletext-generator";
import useAutoFocus from "hooks/useAutoFocus";

const Index = () => {
  const [text, setText] = useState("");
  const [wordCount, setWordCount] = useState(0);
  const [loader, setLoader] = useState(false);
  const [seeMoreText, setSeeMoreText] = useState(false);
  const textRef = useRef(null);
  const [isTextOverflowing, setIsTextOverflowing] = useState(false);
  const location = useLocation();
  const [queryParams, setQUeryParams] = useState(false);
  const [shareId, setShareId] = useState(null);
  const [result, setResult] = useState(null);
  const [error, setError] = useState(false);
  const [fileUploadLoading, setFileUploading] = useState(null);
  const [uploadError, setUploadError] = useState(null);
  const [sizeLimitError, setSizeLImitError] = useState(false);
  const [file, setFile] = useState(false);
  const [inputLang, setInputLang] = useState("");
  const [textLoader, setTextLoader] = useState(false);
  const debouncedInputText = useDebounce(text);
  const [isEnterText, setIsEnterText] = useState(true);
  const [urlText, setUrlText] = useState("");

  useAutoFocus("textarea");
  useEffect(() => {
    if (debouncedInputText?.trim()?.length >= 3) {
      detectLanguageForSource(debouncedInputText);
    }
  }, [debouncedInputText]);
  const detectLanguageForSource = async (text) => {
    const { languageCode } = await detectLanguage(text);
    if (languageCode) {
      setInputLang(languageCode);
    }
  };

  const generateReport = async () => {
    setLoader(true);
    setError("");
        let postData;
        if (urlText){
          postData = {
            tool_name: "source_text_checker",
            user_url: urlText,
          };
        } else {
          postData = {
            tool_name: "source_text_checker",
            user_text: text,
            source_language_code: inputLang,
        
          };
        }

    try {
      const response = await http().post(endpoints.forms.aiTools,postData);

      const newShareId = response?.meta?.share_id;
      const sanitizedShareId = newShareId.replace(/\u200B/g, "");
      const newUrl = `/tools/source-text-checker/result?share-id=${sanitizedShareId}`;
      if (newShareId === undefined && !response.success) {
        setError("Unable to generate a response. Please try again.");
        return;
      }
      navigate(newUrl);
    } catch (error) {
      console.error("Error during quality analysis:", error);
      setError(error);
      setLoader(false);
    } finally {
    }
  };

  const params = new URLSearchParams(location.search);
  const UpdatreshareId = params.get("share-id");

  useEffect(() => {
    if (result && shareId) {
      setQUeryParams(true);
    }
  }, [result, shareId]);

  useEffect(() => {
    const checkTextOverflow = () => {
      if (textRef.current) {
        const lineHeight = parseFloat(
          getComputedStyle(textRef.current).lineHeight
        );
        const maxLinesHeight = lineHeight * 3;

        if (textRef.current.scrollHeight > maxLinesHeight) {
          setIsTextOverflowing(true);
        } else {
          setIsTextOverflowing(false);
        }
      }
    };
    checkTextOverflow();
  }, [text, queryParams]);

  const handleTextarea = (e) => {
    let newText = e.target.value;
    const words = newText
      .trim()
      .split(/\s+/)
      .filter((word) => word.length > 0);

    // if (words.length > 1000) {
    //   newText = words.slice(0, 1000).join(" ");
    // }

    setText(newText);
  };
  useEffect(() => {
    const wordLength = (text || "")
      .trim()
      .split(/\s+/)
      .filter((word) => word.length > 0).length;

    setWordCount(wordLength);
  }, [text]);

  const uploadFile = async (e) => {
    setSizeLImitError(false);
    setUploadError(false);
    const selectedFile = e.target.files[0];
    const extension = fileExtension(selectedFile.name);

    // if (extension) return;
    // setFile(selectedFile);
    if (!extension) {
      setUploadError(true);
      setFile(selectedFile);
      return;
    }
    setFile(selectedFile);
    if (selectedFile.size >= 15000000) {
      // alert.error(translatedData?.toast_text);
      alert("File is too large. Please upload file less than 10 MB.");
      setSizeLImitError(true);
      return;
    }

    const formData = new FormData();
    formData.append("file", selectedFile);

    // console.log(formData.get("file"), "Uploaded file");

    try {
      setFileUploading(true);
      setUploadError(null);
      const res = await http().post(endpoints.forms.toolsFileUpload, formData);

      setText(res.data.text);
    } catch (err) {
      console.log(err, "error");
      setUploadError("Error uploading file. Please try again.");
    } finally {
      setFileUploading(false);
    }
  };
  const toggleInput = (isTextInput) => {
    setIsEnterText(isTextInput);
    setUrlText("");
    setText("");
  };
  const handleLinkChange = (e) => {
    const text = e.target.value;
    setUrlText(text);
    // setHasSummary(false);
  };
  return (
    <Layout>
      <SEO
        title="Source Text Checker"
        description="Check your source text for mistake before translation with our AI grammer checker.Get a report on grammer errors,typos,inconsistent terms,and more."
        slug="/tools/source-text-checker"
      />

      <div className="bg-[#F5F5F5]">
        <div className="pt-7">
          <ToolsBreadcrumb />
        </div>
        <div
          className={`max-w-7xl mx-auto px-4 py-12 lg:py-24  ${
            !queryParams && "grid"
          }  gap-12`}
        >
          {result ? (
            <>
              <p className="text-[#424242] font-bold text-lg font-opensans">
                Source text
              </p>
              <hr className="border-[#C7C7C7] border mt-3 mb-4" />
              <div className="shadow-box relative p-3 rounded-lg">
                <div className="flex gap-3">
                  <p
                    ref={textRef}
                    className={`tool pr-5 text-lg font-sans text-[#575757] ${
                      !seeMoreText && " line-clamp-3"
                    }`}
                    dangerouslySetInnerHTML={{
                      __html: text.replace(/\n/g, "<br />"),
                    }}
                  />
                  {/* {text.replace(/\n/g, "<br />")}{" "}
                  </p> */}
                  <svg
                    onClick={() => {
                      setText("");
                      setQUeryParams(false);
                      navigate("/tools/source-text-checker");
                      setWordCount(0);
                      setResult(null);
                      setShareId(null);
                    }}
                    className="absolute top-4 right-3 cursor-pointer"
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="none"
                  >
                    <path
                      d="M11.7472 10.5355C11.9081 10.6964 11.9986 10.9148 11.9986 11.1424C11.9986 11.37 11.9081 11.5884 11.7472 11.7493C11.5862 11.9103 11.3679 12.0007 11.1403 12.0007C10.9126 12.0007 10.6943 11.9103 10.5333 11.7493L6 7.21455L1.46523 11.7479C1.30427 11.9089 1.08595 11.9993 0.858315 11.9993C0.630676 11.9993 0.41236 11.9089 0.251395 11.7479C0.0904294 11.5869 2.39857e-09 11.3686 0 11.141C-2.39857e-09 10.9133 0.0904294 10.695 0.251395 10.5341L4.78616 6.00071L0.252822 1.46595C0.0918573 1.30498 0.00142808 1.08667 0.00142808 0.859029C0.00142808 0.63139 0.0918573 0.413074 0.252822 0.252109C0.413788 0.0911433 0.632104 0.000713906 0.859743 0.000713904C1.08738 0.000713902 1.3057 0.0911433 1.46666 0.252109L6 4.78687L10.5348 0.251394C10.6957 0.0904292 10.914 -3.79247e-09 11.1417 0C11.3693 3.79247e-09 11.5876 0.0904292 11.7486 0.251394C11.9096 0.41236 12 0.630675 12 0.858315C12 1.08595 11.9096 1.30427 11.7486 1.46524L7.21384 6.00071L11.7472 10.5355Z"
                      fill="#69AAE8"
                    />
                  </svg>
                </div>
                {isTextOverflowing && (
                  <div className="mt-4">
                    <button
                      onClick={() => setSeeMoreText(!seeMoreText)}
                      className="mx-auto max-w-max flex items-center text-[#5B93FF] font-semibold font-opensans"
                    >
                      {!seeMoreText ? "See more" : "See less"}
                      <svg
                        className={`ml-2.5 ${
                          seeMoreText && "rotate-180"
                        } ease-in-out duration-150`}
                        xmlns="http://www.w3.org/2000/svg"
                        width="9"
                        height="9"
                        viewBox="0 0 9 9"
                        fill="none"
                      >
                        <path
                          d="M8.5 1.0625L4.74593 4.34765C4.68069 4.40471 4.59222 4.43679 4.5 4.43679C4.40778 4.43679 4.31931 4.40471 4.25407 4.34765L0.5 1.0625"
                          stroke="#5B93FF"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M8.5 4.56323L4.74593 7.84839C4.68069 7.90545 4.59222 7.93753 4.5 7.93753C4.40778 7.93753 4.31931 7.90545 4.25407 7.84839L0.5 4.56323"
                          stroke="#5B93FF"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </button>
                  </div>
                )}
              </div>
            </>
          ) : (
            <div className="grid lg:grid-cols-2  gap-12">
              <div>
                <h1 className="text-[#5B93FF] mt-0 lg:mt-4 text-center md:text-start text-base font-primary font-semibold leading-6 mb-2.5">
                  Source Text Checker
                </h1>
                <h2 className="text-[#0A2641] text-center md:text-start font-bold text-[28px] lg:text-[32px] leading-[28px] lg:leading-[38px] font-primary">
                  Ensure Your Source Text Is Flawless Before Translation
                </h2>
                <p className="text-[#424242] text-center md:text-start text-lg font-opensans leading-[26px] pt-4 md:block hidden">
                  The tool checks for many types of issues, including grammar,
                  spelling, terminology, and formatting. It also finds things
                  that shouldn't be translated, like wildcards, proper nouns,
                  technical terms, and content like URLs and trademarks. It also
                  flags cultural and sensitive content, gender-specific
                  language, currency and measurement units, and legal terms.
                </p>
              </div>
              <div className="flex flex-col lg:items-center gap-3">
                <div className="shadow-box rounded-lg bg-white p-3">
                  <div className="flex gap-3">
                    <button
                      onClick={() => toggleInput(true)}
                      className={`py-1 px-3 rounded-md font-semibold ${
                        isEnterText
                          ? "text-white bg-[#7AA7FF]"
                          : "text-[#7AA7FF] bg-[#F0F5FF]"
                      }`}
                    >
                      Enter Text
                    </button>
                    <button
                      onClick={() => toggleInput(false)}
                      className={`py-1 px-3 rounded-md font-semibold ${
                        isEnterText
                          ? "text-[#7AA7FF] bg-[#F0F5FF]"
                          : "text-white bg-[#7AA7FF]"
                      }`}
                    >
                      Enter URL
                    </button>
                  </div>
                  {isEnterText ? (
                    <>
                      <div className="relative flex gap-3 mt-3 lg:w-[576px] min-h-[268px] w-full">
                        {fileUploadLoading ? (
                          <FileLoadingInput file={file} />
                        ) : sizeLimitError && !fileUploadLoading ? (
                          <ReUploadFile
                            file={file}
                            setFile={setFile}
                            setSizeLImitError={setSizeLImitError}
                            uploadFile={uploadFile}
                          />
                        ) : uploadError && !fileUploadLoading ? (
                          <UnsupportedFile
                            file={file}
                            setSizeLImitError={setSizeLImitError}
                            setFile={setFile}
                            uploadFile={uploadFile}
                            setFileUploadError={setUploadError}
                          />
                        ) : (
                          <>
                            <textarea
                              autoFocus
                              dir={direction(inputLang)}
                              value={text}
                              placeholder={
                                textLoader ? "" : "Type your text here or"
                              }
                              name="message"
                              id="message"
                              className="tool w-full placeholder-[#B4B4B4]  text-black font-primary bg-transparent text-[15px] overscroll-y-none resize-none border-none outline-none block "
                              onChange={(e) => handleTextarea(e)}
                            />
                            {textLoader && (
                              <div className="absolute top-0 left-[14px] text-[#B4B4B4] italic">
                                Adding sample text...
                              </div>
                            )}
                            {!text && !textLoader && (
                              <button
                                className="absolute top-[0px] left-[170px] underline italic text-[#5B93FF] cursor-pointer pointer-events-auto"
                                onClick={() =>
                                  trySampleText(setText, setTextLoader)
                                }
                              >
                                Try Sample Text
                              </button>
                            )}
                          </>
                        )}
                        {text && (
                          <svg
                            onClick={() => setText("")}
                            className="cursor-pointer"
                            xmlns="http://www.w3.org/2000/svg"
                            width="12"
                            height="12"
                            viewBox="0 0 12 12"
                            fill="none"
                          >
                            <path
                              d="M11.7472 10.5355C11.9081 10.6964 11.9986 10.9148 11.9986 11.1424C11.9986 11.37 11.9081 11.5884 11.7472 11.7493C11.5862 11.9103 11.3679 12.0007 11.1403 12.0007C10.9126 12.0007 10.6943 11.9103 10.5333 11.7493L6 7.21455L1.46523 11.7479C1.30427 11.9089 1.08595 11.9993 0.858315 11.9993C0.630676 11.9993 0.41236 11.9089 0.251395 11.7479C0.0904294 11.5869 2.39857e-09 11.3686 0 11.141C-2.39857e-09 10.9133 0.0904294 10.695 0.251395 10.5341L4.78616 6.00071L0.252822 1.46595C0.0918573 1.30498 0.00142808 1.08667 0.00142808 0.859029C0.00142808 0.63139 0.0918573 0.413074 0.252822 0.252109C0.413788 0.0911433 0.632104 0.000713906 0.859743 0.000713904C1.08738 0.000713902 1.3057 0.0911433 1.46666 0.252109L6 4.78687L10.5348 0.251394C10.6957 0.0904292 10.914 -3.79247e-09 11.1417 0C11.3693 3.79247e-09 11.5876 0.0904292 11.7486 0.251394C11.9096 0.41236 12 0.630675 12 0.858315C12 1.08595 11.9096 1.30427 11.7486 1.46524L7.21384 6.00071L11.7472 10.5355Z"
                              fill="#69AAE8"
                            />
                          </svg>
                        )}
                      </div>
                      <div className="flex justify-between items-center mt-1.5">
                        {text ? (
                          <p className="text-right mt-2 text-sm text-[#9C9C9C]">
                            word count: {wordCount}
                          </p>
                        ) : (
                          <>
                            <label
                              htmlFor={`uploadFile `}
                              className="flex gap-2 cursor-pointer"
                            >
                              <img src={UploadIcon} alt="upload_icon" />
                              <span>Upload File</span>
                            </label>
                            <input
                              type="file"
                              className="hidden"
                              id={`uploadFile `}
                              onChange={uploadFile}
                            />
                          </>
                        )}
                        {error && !loader && (
                          <span className="text-[#ff0000] text-lg font-bold leading-6 font-opensans">
                            {error}
                          </span>
                        )}
                        <button
                          disabled={!text || !inputLang}
                          onClick={generateReport}
                          type="submit"
                          className={` ${
                            !text || !inputLang
                              ? "bg-[#E8E8E8] text-[#858585] cursor-not-allowed "
                              : "bg-lightBlue text-white"
                          } text-base font-semibold  font-opensans text-center rounded-md block px-6 py-3 w-[175px]`}
                        >
                          {loader ? <Loader /> : "Check text"}
                        </button>
                      </div>
                    </>
                  ) : (
                    <div className="lg:w-[576px]">
                      <textarea
                        name="url"
                        value={urlText}
                        placeholder="Paste URL here"
                        className={`w-full min-h-[250px]  font-opensans placeholder-[#858585] text-[#1463FF] leading-[26px] py-3 px-3 bg-transparent text-lg overscroll-y-none resize-none border-none outline-none block `}
                        onChange={handleLinkChange}
                        readOnly={loader}
                      />
                      <div className="flex justify-between items-center p-4 mt-8">
                        <span></span>
                        <div className="flex gap-2">
                          <button
                            type="button"
                            className={` ${
                              urlText.length > 0
                                ? "text-[#0A2641] border border-[#0A2641] cursor-pointer"
                                : "border border-[#B0B0B0] text-[#B0B0B0]"
                            } flex gap-2 bg-white px-4 py-2 rounded-md`}
                            onClick={() => setUrlText("")}
                            disabled={urlText.length === 0}
                          >
                            Clear link
                          </button>
                          <button
                            type="button"
                            onClick={generateReport}
                            disabled={!urlText}
                            className={`bg-[#5B93FF] ${
                              !urlText ? " cursor-not-allowed" : ""
                            } text-white px-4 py-2 rounded-md`}
                          >
                            {loader ? <Loader /> : "Check text"}
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <p className="text-[#6E6E6E] font-opensans text-[12px] text-center">
                  Assisted by AI tools. Inaccuracies may occur.
                </p>
                <p className="text-[#424242] text-center md:text-start text-lg font-opensans leading-[26px] mt-[18px] md:hidden block">
                  The tool checks for many types of issues, including grammar,
                  spelling, terminology, and formatting. It also finds things
                  that shouldn't be translated, like wildcards, proper nouns,
                  technical terms, and content like URLs and trademarks. It also
                  flags cultural and sensitive content, gender-specific
                  language, currency and measurement units, and legal terms.
                </p>
              </div>
            </div>
          )}
        </div>
        {/* Outputs */}
        {result && <SourceOutput result={result} error={error} />}
      </div>
      <ToolFaq QaToolFaqData={SourceTextCheckerToolFaqData} />
    </Layout>
  );
};

export default Index;
